import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BlogpostSummary from "../components/BlogpostSummary"
import { UnstyledBlockUl } from "../components/blocks"
import Pagination from "../components/Pagination"

export default function PostList({ data, pageContext }) {
  const { allSanityPost } = data
  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <UnstyledBlockUl mb={["72px", null, "104px"]}>
        {allSanityPost.nodes.map((post) => (
          <BlogpostSummary key={post?.id} Element="li" {...post} />
        ))}
      </UnstyledBlockUl>
      <Pagination currentPage={currentPage} numPages={numPages} />
    </Layout>
  )
}

export const postListData = graphql`
  query ($skip: Int!, $limit: Int!) {
    allSanityPost(
      filter: {
        slug: { current: { ne: null } }
        isFuture: { eq: false }
        publishedAt: { ne: null }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: [DESC] }
    ) {
      nodes {
        ...PostDataFragment
      }
    }
  }
`
