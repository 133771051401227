import React from "react"
import { Flex, Grid, styled } from "@sonos-inc/web-ui-core"
import { defaultGridTemplateColumns } from "../constants/layout"
import Link from "./Link"

const PageList = styled(Flex)({
  gap: "8px",
  marginLeft: "auto",
})

const NonLinkElement = styled("a")({
  "&:hover": {
    cursor: "pointer",
  },
})

export const postsPerPage = 15

export default function Pagination({
  currentPage,
  numPages,
  customPageChangeHandler,
}) {
  if (numPages <= 1) return null

  return (
    <Grid
      gridTemplateColumns={defaultGridTemplateColumns}
      mb={["72px", null, "104px"]}
    >
      <Flex
        gridColumn={["3/26", null, "3/23", "6/25"]}
        fontSize={["14px", null, null, "18px"]}
        fontWeight="500"
      >
        <span>{numPages} pages</span>
        <PageList>
          {Array.from({ length: numPages }, (_, i) => (
            <Link
              key={`pagination-number${i + 1}`}
              href={customPageChangeHandler ? null : `/${i === 0 ? "" : i + 1}`}
              onClick={
                customPageChangeHandler
                  ? () => customPageChangeHandler(i)
                  : null
              }
              customStyles={{
                fontSize: "14px",
                "@media (min-width: 64rem)": {
                  fontSize: "18px",
                },
                textDecoration: currentPage === i + 1 ? "underline" : "none",
              }}
              NonLinkElement={customPageChangeHandler ? NonLinkElement : null}
            >
              {i + 1}
            </Link>
          ))}
        </PageList>
      </Flex>
    </Grid>
  )
}
