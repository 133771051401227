import React, { Fragment } from "react"
import { Block, styled } from "@sonos-inc/web-ui-core"
import Link from "./Link"
import Label from "./Label"

const CommaSpan = styled(Block.withComponent("span"))({
  color: "#979797",
  letterSpacing: "0.5px",
})

export default function CategoryLinks({ categories }) {
  return (
    <>
      {categories &&
        categories.map(({ title }, idx) => {
          const linkContent = (
            <Link
              href={`/category?category=${encodeURIComponent(title)}`}
              key={idx}
              customStyles={{ color: "#979797" }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Label upperCase>{title}</Label>
            </Link>
          )

          return idx > 0 ? (
            <Fragment key={idx}>
              <CommaSpan fontWeight="medium" fontSize={["12px", null, "14px"]}>
                {", "}
              </CommaSpan>
              {linkContent}
            </Fragment>
          ) : (
            linkContent
          )
        })}
    </>
  )
}
