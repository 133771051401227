import React from "react"

import { Flex, Block, IconArrowBoldRight } from "@sonos-inc/web-ui-core"
import Link from "./Link"
import Label from "./Label"
import { PAsBlock } from "./blocks"

import { H1, H3 } from "./typography"
import { presentDate } from "../utils/date"
import CategoryLinks from "./CategoryLink"

const BlockAsIconArrowRight = Block.withComponent(IconArrowBoldRight)

const PAsFlex = Flex.withComponent("p")

export default function BlogpostSummaryMeta({
  title,
  categories,
  publishedAt,
  featured,
}) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      pt={[null, null, "medium"]}
      pb={[null, null, "medium"]}
      height="100%"
      width="100%"
    >
      <Block mb={["small", null, "nil"]}>
        <CategoryLinks categories={categories} />
      </Block>

      {featured ? (
        <H1 Element={PAsBlock} mb={["medium", null, "nil"]}>
          {title}
        </H1>
      ) : (
        <H3 Element={PAsBlock} mb={["medium", null, "nil"]}>
          {title}
        </H3>
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <Link NonLinkElement={PAsFlex}>
          Read More{" "}
          <BlockAsIconArrowRight ml="0.714em" width="1.286em" height="auto" />
        </Link>
        <Label>{presentDate(publishedAt)}</Label>
      </Flex>
    </Flex>
  )
}
