export function presentDate(dateString) {
  try {
    const date = new Date(dateString)
    // for now this blog will be in English only, hence 'en-US' choice
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date)
  } catch (err) {
    return dateString
  }
}
