import React from "react"
import { Image, Grid, Block, styled } from "@sonos-inc/web-ui-core"
import BlogpostSummaryMeta from "./BlogpostSummaryMeta"
import Link from "./Link"
import {
  defaultMobileGridTemplateColumns,
  defaultMobileGridContentColumn,
} from "../constants/layout"

const BlogpostSummaryMetaContainer = styled(Block)({
  "&:hover": {
    cursor: "pointer",
  },
})

export const desktopRegularGridTemplateColumns =
  "[start] repeat(5, 1fr) [image-start] repeat(6,1fr) [image-end] 1fr [meta-start] repeat(10,1fr) [meta-end] repeat(5,1fr)"
const desktopFeaturedGridTemplateColumns =
  "[start] repeat(6, 1fr) [image-start] repeat(9,1fr) [image-end] 1fr [meta-start] repeat(8,1fr) [meta-end] repeat(3,1fr)"

export const tabletRegularGridTemplateColumns =
  "[start] repeat(2, 1fr) [image-start] repeat(6,1fr) [image-end] 1fr [meta-start] repeat(13,1fr) [meta-end] repeat(2,1fr)"
const tabletFeaturedGridTemplateColumns =
  "[start] repeat(2, 1fr) [image-start] repeat(9,1fr) [image-end] 1fr [meta-start] repeat(9,1fr) [meta-end] repeat(2,1fr)"

function BlogpostSummary({ mainImage, featured, slug, Element, ...metaData }) {
  const { path, metadata: imgMetadata } = mainImage?.asset || {}
  const imgUrl = process.env.GATSBY_ASSET_BASE_URL + path

  const postUrl = `/posts/${slug?.current}/`
  const GridAsElement = Grid.withComponent(Element)
  return (
    <GridAsElement
      gridTemplateColumns={[
        defaultMobileGridTemplateColumns,
        null,
        featured
          ? tabletFeaturedGridTemplateColumns
          : tabletRegularGridTemplateColumns,
        featured
          ? desktopFeaturedGridTemplateColumns
          : desktopRegularGridTemplateColumns,
      ]}
      mb={featured ? "68px" : "48px"}
    >
      <Link
        gridColumn={[
          defaultMobileGridContentColumn,
          null,
          "image-start / image-end",
        ]}
        href={postUrl}
        isUnstyled
        mb={["large", null, "nil"]}
      >
        <Image
          loading="lazy"
          src={imgUrl}
          aspectRatio={imgMetadata?.dimensions?.aspectRatio}
          lqipUri={imgMetadata?.lqip}
          lqipColor={imgMetadata?.palette?.dominant?.background}
          alt={mainImage?.altText}
        />
      </Link>

      <BlogpostSummaryMetaContainer
        gridColumn={[
          defaultMobileGridContentColumn,
          null,
          "meta-start / meta-end",
        ]}
        onClick={() => {
          window.location.href = postUrl
        }}
      >
        <BlogpostSummaryMeta featured={featured} {...metaData} />
      </BlogpostSummaryMetaContainer>
    </GridAsElement>
  )
}

export default React.memo(BlogpostSummary)
